<template>
    <div class="drawer">
        <cds-navigation expanded>
            <!-- <cds-navigation-start /> -->
            <DrawerHeader />
            <!-- Dashboard -->
            <cds-navigation-item :active="isActive('Dashboard')">
                <router-link to="/dashboard" cds-layout="p-t:sm p-b:sm">
                    <cds-icon shape="grid-chart" size="20" />
                    Dashboard
                </router-link>
            </cds-navigation-item>
            <cds-divider />

            <!-- Dynamic Drawer Items -->
            <div v-for="drawerItem in this.drawerItems" :key="drawerItem">
                <cds-navigation-item :active="isDynamicActive(drawerItem.id)">
                    <router-link :to="{ path: '/dynamic/list', query: { name: drawerItem.id } }"
                        cds-layout="p-t:sm p-b:sm">
                        <cds-icon :shape="drawerItem.label" size="20" />
                        {{ drawerItem.label }}
                    </router-link>
                </cds-navigation-item>
                <cds-divider />
            </div>
        </cds-navigation>
    </div>
</template>

<script>
import '@cds/core/navigation/register.js';
import '@cds/core/divider/register.js';
import { ClarityIcons, gridChartIcon } from '@cds/core/icon';
import DrawerHeader from './DrawerHeader.vue';

export default {
    props: {
        drawerItems: Array
    },
    components: {
        DrawerHeader
    },
    mounted() {
        ClarityIcons.addIcons(gridChartIcon);
    },
    watch: {
        drawerItems: function () {
            this.drawerItems.forEach(drawerItem => {
                const icon_name = drawerItem.label;
                const icon_svg = drawerItem.icon;

                ClarityIcons.addIcons([icon_name, icon_svg]);
            });
        }
    },
    methods: {
        isActive: function (name) {
            return this.$route.name == name;
        },
        isDynamicActive: function (name) {
            return this.$route.query.name == name;
        }
    }
}
</script>

<style scoped>
.drawer {
    height: 100%;
    border-right: 1px solid lightgray;
}
</style>