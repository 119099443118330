<template>
  <component :is="this.currentView" @onLogin="user => initializeApplication(user)"
    @onInitializationDone="updateView('AppView')" />
</template>

<script>
import AppView from './AppView.vue';
import LoginView from './LoginView.vue';
import store from './store/index.js';
import RestClient from './js/rest/RestClient.js';
import ConfigurationParser from './js/ConfigurationParser.js';

export default {
  components: {
    AppView,
    LoginView
  },
  data() {
    return {
      currentView: "LoginView"
    }
  },
  methods: {
    initializeApplication: async function (user) {
      await this.saveUserToStore(user);
      store.dispatch("user/activateAutomaticTokenRefresh");
      console.log(store.getters["user/getIdToken"]);

      //Setting up Application -> Load Configuration for Gravemanagement and save to store
      const restClient = new RestClient();

      // await restClient.getGraveyards()
      //   .then(response => store.state.graveyards = response.data);
      let retry = 0;
      let configLoadingFailure = true;
      do {
        await restClient.getConfiguration()
          .then(response => {
            if (response == null) {
              retry++;
            } else {
              store.dispatch("management/setConfiguration", response.data);
              retry = 10;
              configLoadingFailure = false;
            }
          }); // -> !!! Value is used in ConfigurationParser.js file
        await new Promise(r => setTimeout(r, 1000));
      } while (retry < 10)

      if (configLoadingFailure) {
        return console.log("Config coulnd't load");
      }

      //Setting up Application -> Load loading global CSS-variables
      const configurationParser = new ConfigurationParser();
      const properties = configurationParser.getGraveManagementProperties();
      document.documentElement.style.setProperty("--base-color", properties.basecolor);

      //Setting up Application -> end
      store.dispatch("management/setConfigurationLoading", false);
    },
    updateView: function (view) {
      const currentPath = this.$router.options.history.state.current;
      const redirect = currentPath.includes("/login") ? "/" : currentPath;
      this.$router.push(redirect)
      this.currentView = view;
    },
    saveUserToStore: async function (user) {
      store.dispatch("user/setUser", user);
      store.dispatch("user/setIdToken", await user.getIdToken());
    }
  }
}
</script>

<style>

.button {
    --background: var(--base-color);
    --color: white;
}

</style>