import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default async function login(username, password) {
    const auth = getAuth();
    let user = null;

    await signInWithEmailAndPassword(auth, username, password)
        .then((userCredentials) => {
            user = userCredentials.user;
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage)
        });

    return user;
}