import store from "@/store";
import router from "@/router";
import { getAuth, signOut } from "firebase/auth";
import publishAlert from "../util/Alert";

async function logout() {

    const auth = getAuth();
    signOut(auth).then(() => {
        store.dispatch("user/setUser", null);
        store.dispatch("user/setIdToken", null);
        router.go(0);
    }).catch((error) => {
        publishAlert("danger", error);
    });
}

export default logout;