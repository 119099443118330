const user = {
    namespaced: true,
    state: () => ({
        user: null,
        idToken: null
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        getIdToken(state) {
            return state.idToken;
        },
        getEmail(state) {
            if (state.user == undefined) return null;
            return state.user.email;
        },
        getTokenExpirationTime(state) {
            return state.user.stsTokenManager.expirationTime;
        },
        getNextRefresh(state) {
            const tokenExpirationDate = new Date(state.user.stsTokenManager.expirationTime);
            const currentTime = new Date();
            const bufferInMillis = 60000;
            return tokenExpirationDate - currentTime - bufferInMillis;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setIdToken(state, idToken) {
            state.idToken = idToken;
        },
        activateAutomaticTokenRefresh(state, getters) {
            let timer = getters.getNextRefresh;
            setInterval(() => {
                const user = getters.getUser;
                user.getIdToken(true)
                    .then(idToken => {
                        state.idToken = idToken;
                        console.log(idToken)
                        timer = getters.getNextRefresh;
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }, timer);
        }
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user);
        },
        setIdToken({ commit }, idToken) {
            commit('setIdToken', idToken);
        },
        activateAutomaticTokenRefresh({ commit, getters }) {
            commit('activateAutomaticTokenRefresh', getters);
        }
    },
}

export default user;