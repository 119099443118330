import { getAuth } from "firebase/auth";

async function getUserFromBrowser() {
    const auth = getAuth();
    if (auth == undefined) return null;

    const user = await new Promise((resolve) => {
        auth.onAuthStateChanged(function (user) {
            resolve(user);
        });
    });

    return user;
}


export default getUserFromBrowser;