import RequestBuilder from "./RequestBuilder.js";
// import Beispielkonfiguration from "../../../../BeispielKonfiguration.json"
import store from "@/store/index.js";

export default class RestClient {

    constructor(idToken = store.getters["user/getIdToken"]) {
        this.requestBuilder = new RequestBuilder(idToken);
    }

    async getGraveyards() {
        const endpoint = "/graveyards";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async getConfiguration() {
        const endpoint = "/config";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async getTableRows(table) {
        const endpoint = `/table/${table}`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return [];
            });
    }

    async getFormData(table) {
        const endpoint = `/table/${table}/form`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getAllRelations(table, id) {
        const endpoint = `/table/${table}/${id}/find`;
        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async postObject(table, requestBody) {
        const endpoint = `/table/${table}`;

        return await this.requestBuilder.post(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getObject(table, id) {
        const endpoint = `/table/${table}/${id}`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async patchObject(table, id, requestBody) {
        const endpoint = `/table/${table}/${id}`;

        return await this.requestBuilder.patch(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async deleteObject(table, id) {
        const endpoint = `/table/${table}/${id}`;

        return await this.requestBuilder.delete(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }
}