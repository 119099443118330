import { createRouter, createWebHashHistory } from "vue-router";
import getUserFromBrowser from "@/js/authentication/GetUserFromBrowser.js";

const routes = [
  {
    path: "/",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      auth: true,
      menue_options: ["edit_dashboard", "settings", "export"],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../LoginView.vue"),
    meta: { auth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      auth: true,
      menue_options: ["edit_dashboard", "settings", "export"],
    },
  },
  {
    path: "/dynamic/list",
    name: "DynamicList",
    props: true,
    component: () => import("@/views/TableView.vue"),
    meta: {
      auth: true,
      menue_options: ["settings", "export"],
    },
  },
  {
    path: "/dynamic/create",
    name: "DynamicCreate",
    props: true,
    component: () => import("@/views/FormView.vue"),
    meta: {
      auth: true,
      menue_options: ["settings"],
    },
  },
  {
    path: "/dynamic/detail",
    name: "DynamicDetail",
    props: true,
    component: () => import("@/views/DetailView.vue"),
    meta: {
      auth: true,
      menue_options: ["settings"],
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/SettingsView.vue"),
    meta: {
      auth: true,
      menue_options: ["gravemanagement"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("./NotFound.vue"),
    meta: {
      auth: true,
      menue_options: ["gravemanagement", "settings"],
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth && (await getUserFromBrowser()) == null)
    return next("/login");
  if (to.name == "Login" && (await getUserFromBrowser()) != null)
    return next("/dashboard");
  return next();
});

export default router;
