import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
require("../node_modules/modern-normalize/modern-normalize.css")
require("../node_modules/@cds/core/global.min.css")
require("../node_modules/@cds/city/css/bundles/default.min.css")
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAnd1gYf7jgiuQb9WYJq0Bwu3O1bU-rjrE",
    authDomain: "grabis-e255f.firebaseapp.com",
    projectId: "grabis-e255f",
    storageBucket: "grabis-e255f.appspot.com",
    messagingSenderId: "731584839365",
    appId: "1:731584839365:web:4655797073028dc973faac",
    measurementId: "G-WDSBNG3XXM",
};

initializeApp(firebaseConfig);

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app');

