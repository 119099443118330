<template>
    <PhoneHeader cds-layout="horizontal align:center display@sm:none display:grid" />

    <DesktopHeader cds-layout="horizontal display@sm:flex display:none" />
</template>
  
<script>
import DesktopHeader from "./desktop/DesktopHeader.vue";
import PhoneHeader from "./phone/PhoneHeader.vue"

export default {
    components: {
        DesktopHeader,
        PhoneHeader
    }
}
</script>
  
<style scoped></style>