import { createStore } from 'vuex';
import util from './util.module';
import management from './management.module';
import user from './user.module';

export default createStore({
  modules: {
    util,
    management,
    user
  }
})
