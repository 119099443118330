const management = {
    namespaced: true,
    state: () => ({
        isConfigurationLoading: true,
        configuration: null
    }),
    getters: {
        isConfigurationLoading(state) {
            return state.isConfigurationLoading;
        },
        getConfiguration(state) {
            return state.configuration;
        }
    },
    mutations: {
        setConfigurationLoading(state, loading) {
            state.isConfigurationLoading = loading;
        },
        setConfiguration(state, configuration) {
            state.configuration = configuration;
        }
    },
    actions: {
        setConfigurationLoading({ commit }, loading) {
            commit('setConfigurationLoading', loading);
        },
        setConfiguration({ commit }, configuration) {
            commit('setConfiguration', configuration);
        }
    },
}

export default management;