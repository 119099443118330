<template>
    <DesktopDrawer :drawerItems="this.visibleTables" cds-layout="display@sm:block display:none" />

    <PhoneDrawer :drawerItems="this.visibleTables" cds-layout="display@sm:none" v-if="$store.state.phoneDrawerExpanded" />
</template>
  
<script>
import store from '@/store';
import DesktopDrawer from './desktop/DesktopDrawer.vue';
import PhoneDrawer from './phone/PhoneDrawer.vue';
import ConfigurationParser from '@/js/ConfigurationParser.js';

export default {
    setup() {
        // Needs to be initialized here, because PhoneDrawer can be changed from 'HeaderComponent' 
        store.state.phoneDrawerExpanded = false;
    },
    mounted() {
        const configurationParser = new ConfigurationParser();

        this.visibleTables = configurationParser.getVisibleTables();
    },
    components: {
        DesktopDrawer,
        PhoneDrawer
    },
    data() {
        return {
            visibleTables: []
        }
    }
}
</script>
  
<style scoped></style>