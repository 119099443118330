import config from "@/config/Config.js";

export default class RequestBuilder {
  constructor(accessToken) {
    this.accessToken = accessToken;
  }

  async get(url, body) {
    return this.reponseBuilder("GET", url, body);
  }

  async post(url, body) {
    return this.reponseBuilder("POST", url, body);
  }

  async put(url, body) {
    return this.reponseBuilder("PUT", url, body);
  }

  async patch(url, body) {
    return this.reponseBuilder("PATCH", url, body);
  }

  async delete(url) {
    return this.reponseBuilder("DELETE", url);
  }

  async reponseBuilder(method, url, body) {
    const request_url = config.api_endpoint + url;

    try {
      return await fetch(request_url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
          Graveyard: "7",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.accessToken,
        },
      });
    } catch (error) {
      // TODO: Better error handling
      console.log(error);
      return null;
    }
  }
}
