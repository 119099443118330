<template>
    <div cds-layout="horizontal m:xs gap:xs align:vertical-center" @click="changeRoute">
        <span>
            <slot name="icon" />
        </span>
        <span cds-text="section semibold">
            <slot name="name" />
        </span>
    </div>
</template>

<script>
import store from '@/store';

export default {
    props: {
        routeName: String,
        routeQuery : Object
    },
    methods: {
        changeRoute: function () {
            if (this.routeName == undefined) {
                return;
            }

            let route = {
                path  : this.routeName,
                query : this.routeQuery
            }

            this.$router.push(route);
            store.state.phoneDrawerExpanded = false;
        }
    }
}
</script>

<style></style>