<template>

    <div cds-layout="vertical align:stretch">
        <div cds-layout="vertical align:shrink" class="login-container">
            <div class="cross horizontal">
                <span class="title">
                    <template v-if="!this.loadingGravemanagement">
                        Grabverwaltung
                    </template>
                    <template v-else>
                        &#8205;
                    </template>
                </span>
                <div class="cross vertical" />
                <div class="loading-bar" />
            </div>

            <div cds-layout="vertical gap:xxl" class="cross body">

                <template v-if="!this.loadingGravemanagement">
                    <cds-input>
                        <label></label>
                        <input placeholder="Benutzername" v-model="this.username" />
                        <cds-control-action action="suffix" readonly aria-label="user">
                            <cds-icon shape="user" solid />
                        </cds-control-action>
                        <cds-control-message v-if="this.error" status="error" />
                    </cds-input>

                    <cds-password>
                        <label></label>
                        <input type="password" placeholder="Passwort" v-model="this.password" />
                        <cds-control-message v-if="this.error" status="error">Benutzername oder Passwort ist
                            falsch!</cds-control-message>
                    </cds-password>

                    <div cds-layout="horizontal align:stretch p-l:xxxl p-r:xxxl m-t:xxxl">
                        <cds-button @click="login()" class="login-button"
                            :loading-state="this.initFirebase">Anmelden</cds-button>
                    </div>
                </template>

                <template v-else>
                    <span cds-text="section medium" class="loading-text">
                        Die Grabverwaltung wird vorbereitet ...
                    </span>
                </template>
            </div>
        </div>

    </div>

</template>

<script>
import getUserFromBrowser from './js/authentication/GetUserFromBrowser.js';
import '@cds/core/input/register.js';
import '@cds/core/password/register.js';
import '@cds/core/icon/register.js';
import { ClarityIcons, userIcon } from '@cds/core/icon';
import store from './store';
import loginFirebase from './js/authentication/Login';

export default {
    data() {
        return {
            username: "",
            password: "",
            error: false,
            initFirebase: "default",
            loadingGravemanagement: null
        }
    },
    async mounted() {
        const user = await getUserFromBrowser();

        if (user) {
            await this.login(user);
        }
        this.addKeyListener();
        ClarityIcons.addIcons(userIcon);
    },
    methods: {
        addKeyListener: function () {
            window.addEventListener("keypress", function (event) {
                if (event.key === "Enter") this.login();
            }.bind(this));
        },
        login: async function (user = null) {
            if (!user) {
                const username = this.username.trim();
                const password = this.password.trim();

                this.initFirebase = "loading";
                user = await loginFirebase(username, password);
                if (user == null) {
                    return this.showError();
                }
                this.initFirebase = "default";
            }

            this.$emit("onLogin", user);

            this.loadingGravemanagement = true;
            await this.loadGravemanagement();
            this.loadingGravemanagement = false;

            this.$emit("onInitializationDone");
        },
        showError: function () {
            this.password = "";
            this.initFirebase = false;
            this.error = true;
            this.initFirebase = "default";
        },
        loadGravemanagement: async function () {
            const cross_horizontal = document.querySelector(".cross.horizontal");
            cross_horizontal.style.borderBottom = "0.5px solid lightgray";
            cross_horizontal.style.boxShadow = "none";
            const loadingBar = document.querySelector(".loading-bar");
            const totalSteps = 200;
            let currentStep = 0;

            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (currentStep < totalSteps) currentStep++;

                    const progressPercentage = (currentStep / totalSteps) * 90;
                    loadingBar.style.width = progressPercentage + "%";


                    if (currentStep >= totalSteps && store.getters["management/isConfigurationLoading"] == false) {
                        loadingBar.style.width = "100 %";
                        clearInterval(interval);
                        resolve();
                    }
                }, 20);
            });
        }
    }
}
</script>

<style scoped>
.login-container {
    align-items: center;
    margin-top: 150px;
}

.title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48.41px;
}

.login-button {
    --background: #E9E9E9;
    box-shadow: 0px 2px 2px 0px #00000040;
    --color: black;
    --border-radius: 0px
}

.loading-container {
    height: 245px;
}

.loading-text {
    color: #8A8A8A;
}

.cross.horizontal {
    position: relative;
    border-bottom: 1.5px solid #526B51;
    box-shadow: 0px 4px 2px -2px #00000040;
    min-width: 500px;
    width: 60%;
    max-width: 800px;
    padding-left: 150px;
    padding-bottom: 5px;
}

.cross.vertical {
    position: absolute;
    border-right: 1.5px solid #526B51;
    height: 500px;
    left: 120px;
    top: -40px;
}

.cross.body {
    min-width: 500px;
    width: 60%;
    padding-left: 150px;
    max-width: 700px;
    margin-top: 100px;
}

.loading-bar {
    position: absolute;
    left: 0px;
    bottom: -1px;
    border-bottom: 1.5px solid #526B51;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 0%;
}
</style>