<template>
    <div class="drawer">
        <div class="main" cds-layout="vertical p:md">
            <div cds-layout="horizontal gap:sm align:right align:vertical-center">
                <cds-button action="flat" status="neutral" @click="closePhoneDrawer">
                    <span cds-text="caption">Übersicht schließen</span>
                    <cds-icon shape="times"></cds-icon>
                </cds-button>

            </div>
            <div cds-layout="vertical gap:lg m-t:md m-b:md">
                <!-- Dashboard -->
                <NavigationTemplate routeName="/dashboard">
                    <template v-slot:icon>
                        <cds-icon shape="grid-chart" size="md" />
                    </template>
                    <template v-slot:name>
                        Dashboard
                    </template>
                </NavigationTemplate>

                <!-- Dynamic Drawer Items -->
                <NavigationTemplate v-for="drawerItem in this.drawerItems" :key="drawerItem" routeName="/dynamic/list"
                    :routeQuery="{ name: drawerItem.tableName }">
                    <template v-slot:icon>
                        <cds-icon :shape="drawerItem.tableName" size="md" />
                    </template>
                    <template v-slot:name>
                        {{ drawerItem.tableName }}
                    </template>
                </NavigationTemplate>

                <!-- Einstellungen -->
                <NavigationTemplate routeName="/settings">
                    <template v-slot:icon>
                        <cds-icon shape="cog" size="md" />
                    </template>
                    <template v-slot:name>
                        Einstellungen
                    </template>
                </NavigationTemplate>

                <!-- Logout -->
                <NavigationTemplate @click="logout">
                    <template v-slot:icon>
                        <cds-icon shape="logout" size="md" />
                    </template>
                    <template v-slot:name>
                        Logout
                    </template>
                </NavigationTemplate>
            </div>

        </div>

        <div class="bottom" />
    </div>
</template>

<script>
import '@cds/core/internal-components/close-button/register.js';
import { ClarityIcons, timesIcon, gridChartIcon, arrowIcon, cogIcon, logoutIcon } from '@cds/core/icon';
import store from '@/store';
import NavigationTemplate from './NavigationTemplate.vue';
import logout from "@/js/authentication/Logout.js"

export default {
    props: {
        drawerItems: Array
    },
    components: {
        NavigationTemplate
    },
    mounted() {
        ClarityIcons.addIcons(timesIcon, gridChartIcon, arrowIcon, cogIcon, logoutIcon)
    },
    watch: {
        drawerItems: function () {
            this.drawerItems.forEach(drawerItem => {
                const icon_name = drawerItem.tableName;
                const icon_svg = drawerItem.icon;

                ClarityIcons.addIcons([icon_name, icon_svg]);
            });
        }
    },
    methods: {
        closePhoneDrawer: function () {
            store.state.phoneDrawerExpanded = false;
        },
        logout: async function () {
            await logout();
        }
    }
}
</script>

<style scoped>
.drawer {
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    z-index: 1;
}

.main {
    background-color: white;
    position: relative;
}

.bottom {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>