<template>
    <div class="header">
        <div cds-layout="horizontal align:stretch align:vertical-center p-r:md p-l:md" class="header-buttons">
            <div cds-layout="horizontal align:left align:vertical-center align:shrink" class="clickable"
                @click="this.goBack()">
                <cds-icon shape="angle" size="30" direction="left" />
                Zurück
            </div>
            <div cds-layout="horizontal align:shrink align:vertical-center" class="clickable"
                @click="this.toggleOptions()">
                <cds-icon shape="ellipsis-vertical" size="30" />
            </div>
        </div>
        <div class="options" cds-layout="vertical align:stretch" v-if="this.showOptions">
            <div class="text" v-if="showOption('edit_dashboard')">Dashboard anpassen</div>
            <cds-divider v-if="showOption('edit_dashboard')" />
            <div class="text" @click="this.routeTo('/')" v-if="showOption('gravemanagemt')">Grabverwaltung</div>
            <cds-divider v-if="showOption('gravemanagemt')" />
            <div class="text">App Info</div>
            <cds-divider />
            <div class="text" v-if="showOption('export')">Seite exportieren</div>
            <cds-divider v-if="showOption('export')" />
            <div class="text" @click="this.routeTo('/settings')" v-if="showOption('settings')">Einstellungen</div>
            <cds-divider v-if="showOption('settings')" />
            <div class="text" @click="this.logout()">Abmelden</div>
        </div>
    </div>
</template>

<script>
import { ClarityIcons, ellipsisVerticalIcon, angleIcon } from '@cds/core/icon';
import '@cds/core/divider/register.js';
import logout from '@/js/authentication/Logout';

export default {
    mounted() {
        ClarityIcons.addIcons(ellipsisVerticalIcon, angleIcon);
        this.updateMenueOptions();
    },
    data() {
        return {
            showOptions: false,
            menue_options: []
        }
    },
    watch: {
        $route() {
            this.updateMenueOptions();
        }
    },
    methods: {
        updateMenueOptions: function () {
            const menue_options = this.$router.currentRoute.value.meta["menue_options"];
            if (menue_options == null || menue_options == undefined) return;
            this.menue_options = JSON.parse(JSON.stringify(menue_options));
        },
        toggleOptions: function () {
            this.showOptions = !this.showOptions;
        },
        logout: async function () {
            await logout();
        },
        showOption: function (option) {
            return this.menue_options.includes(option);
        },
        goBack: function () {
            this.$router.back();
        },
        routeTo: function (path) {
            this.$router.push(path);
            this.toggleOptions();
        }
    }
}
</script>

<style scoped>
.header {
    background-color: var(--base-color);
    color: white;
    min-height: 52px;
    max-height: 52px;
}

.header-buttons {
    justify-content: space-around;
}

.clickable:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.options {
    background-color: white;
    z-index: 99999;
    position: absolute;
    right: 0px;
    margin-top: 52px;
    min-width: 250px;
    border: 1px solid #B4B4B4;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.options .text {
    font-size: 16px;
    padding: 10px;
    color: #000000;
}

.options div:hover {
    background-color: #B4B4B4;
    cursor: pointer;
}
</style>