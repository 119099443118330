import store from "@/store";

async function publishAlert(status = "default", message = "") {
    const alert = {
        status: status,
        message: message
    }
    store.dispatch('util/pushAlert', alert);
    new Promise(resolve => setTimeout(resolve, 5000))
        .then(() => store.getters['util/getAlerts'].shift());
}

export default publishAlert;