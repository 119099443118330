<template>
    <div class="drawerHeader" cds-layout="vertical p-t:md p-b:md">
        <div cds-layout="grid">
            <img src="../../../assets/cross.svg" height="60" width="60" cds-layout="col:1" />
            <div cds-layout="grid cols:12 gap:xs col:11">
                <span cds-text="bold">Grabverwaltung</span>
                <span cds-text="secondary semibold">{{ this.graveManagementName }}</span>
                <span cds-text="caption" cds-layout="p-t:xs">{{ this.username }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ConfigurationParser from '@/js/ConfigurationParser';

export default {
    data() {
        const configurationParser = new ConfigurationParser();
        const graveManagementName = configurationParser.getGraveManagementName();
        const username = "Max Mustermann";


        return {
            graveManagementName,
            username
        }
    },
}
</script>

<style scoped>
.drawerHeader {
    border-bottom: 1px solid lightgray;
}
</style>