<template>
    <cds-alert-group :status="this.status">
        <cds-alert>
            {{ this.message }}
        </cds-alert>
    </cds-alert-group>
</template>

<script>
import '@cds/core/alert/register.js';

export default {
    props: {
        status: String,
        message: String
    }
}
</script>

<style scoped></style>